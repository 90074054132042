import get from '@invitato/helpers/dist/getLocale';

export default {
  title: get('Waktu dan Tempat', 'Save The  <br />Date'),
  akad: get('akad cinta', 'akad cinta'),
  holy: get('pemberkatan', 'holy <br /> matrimony'),
  reception: get('resepsi', 'reception'),
  subtitle: get('Waktu & Tempat', 'Place & Time'),
  place: get('Tempat', 'Place'),
  time: get('Waktu', 'Time'),
  date: get('Tanggal', 'Date'),
  seeLocation: get('Lihat Lokasi', 'See Location'),
  resepsiDetai: get(
    'Kami mohon kehadiran bapak/ibu/saudara/i pada resepsi pernikahan yang akan dilaksanakan pada:',
    'join us in our celebration at the wedding reception on the:',
  ),
};
