import { css } from '@emotion/react';

export const styleGift = css`
  .butterfly-gift-left {
    position: absolute;
    left: 70px;
    top: -30px;
    width: 30px;
    z-index: 2;
  } 
  .butterfly-gift-right {
    position: absolute;
    right: -8px;
    top: -30px;
    width: 30px;
    z-index: 2;
  } 
  @media screen and (max-width:420px) {
    .butterfly-gift-left {
      position: absolute;
      left: 0px;
      top: -30px
    } 
    .butterfly-gift-right {
      position: absolute;
      right: -30px;
      top: -30px
    } 
  }
`