import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Heading,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
} from '@chakra-ui/react';

import {
  ENABLE_BANK_TRANSFER,
  ENABLE_SEND_GIFT,
  ENABLE_GIFT_CONFIRMATION,
} from '@/constants/feature-flags';

import Bank from '@invitato/helpers/dist/constants/banks';
import txtWording from './locales';
import {
  BUTTON_PADDING_PROPS,
  DEFAULT_BUTTON_PROPS
} from '@/constants/colors';
import {
  HEADING_STYLES,
  CONTENT_STYLES,
} from '@/constants/typography';

import { ASSETS_BUTTERFLY, ASSETS_PAPER_BG } from '@/constants/assets';
import Confirmation from '../Confirmation/Lazy';
import { styleGift } from './styles'

function ModalGiftCard({ onClose, visible, onCopyText, lang }) {
  const name = 'Astri & Akbar';
  const address = `Jl. Jakarta 1, DKI Jakarta`;
  const hp = `62123456789`;

  const renderBank = (bank, userName, bankNumber) => {
    return (
      <Box padding="16px 0" textAlign="center" {...CONTENT_STYLES}>
        <Center>
          <Image src={bank.logo} marginBottom="8px" maxWidth="80px" />
        </Center>
        <Text color="secondaryColorText" py="4px" fontWeight="bold">
          {bankNumber}
        </Text>
        <Text color="mainColorText" fontWeight="bold">{userName}</Text>
        <Center>
          <Button
            {...DEFAULT_BUTTON_PROPS}
            {...BUTTON_PADDING_PROPS}
            mt="16px"
            fontSize="12px"
            padding="1em 1.5em"
            onClick={() => onCopyText(bankNumber)}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent maxWidth="500px">
        <DrawerCloseButton marginTop="12px" />
        <DrawerBody 
          padding={'2rem 3rem'} 
          overflowX="hidden"
          bgImage={ASSETS_PAPER_BG}
          css={styleGift}
          {...CONTENT_STYLES}>
          {ENABLE_BANK_TRANSFER && (
            <>
              {/* Bank Section */}
                <Box
                  margin="24px auto 0 auto"
                  textAlign="center"
                  position="relative"
                >
                  <Heading {...HEADING_STYLES} fontSize="36px" letterSpacing="2px" lineHeight="1.7em" color="mainColorText" pos="relative">
                    Bank Transfer
                    <Image src={ASSETS_BUTTERFLY} className="butterfly-gift-left" />
                  </Heading>
                </Box>

                <Box marginTop={'8px'}>{renderBank(Bank.bca, 'Astri & Akbar', '1234567')}</Box>
            </>
          )}

          {/* Gift Section */}
          {ENABLE_SEND_GIFT && (
              <Box m="40px 0 24px 0">
                <Box
                  width="55%"
                  margin="24px auto 0 auto"
                  textAlign="center"
                  position="relative"
                >
                  <Heading {...HEADING_STYLES} fontSize="36px" letterSpacing="2px" lineHeight="1.7em" color="mainColorText">
                    {txtWording.sendGift[lang]}
                    <Image src={ASSETS_BUTTERFLY} className="butterfly-gift-right" transform="rotateY(180deg)" />
                  </Heading>
                </Box>

                <Box padding="16px 0" {...CONTENT_STYLES}  textAlign="center" >
                  <Text color="mainColorText" marginTop="0" fontWeight="bold">
                    {name} <br />
                  </Text>
                  <Text>
                    {address}
                  </Text>
                  <Text>
                    {hp}
                  </Text>
                  <Center>
                    <Button
                      {...DEFAULT_BUTTON_PROPS}
                      {...BUTTON_PADDING_PROPS}
                      mt="16px"
                      fontSize="12px"
                      padding="1em 1.5em"
                      onClick={() =>
                        onCopyText(`${name} - ${address} - ${hp}`, txtWording.successCopyAddress[lang])
                      }
                    >
                      {txtWording.modalCopyAddress[lang]}
                    </Button>
                  </Center>
                </Box>
              </Box>
          )}

          {ENABLE_GIFT_CONFIRMATION && (
              <Confirmation lang={lang} />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalGiftCard.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalGiftCard.defaultProps = {
  lang: 'id',
};

export default ModalGiftCard;