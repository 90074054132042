import React from 'react';
import { string } from 'prop-types';
import { Flex } from '@chakra-ui/react';

import useDateCountdown from '@/hooks/useDateCountdown';
import WithAnimation from '@/components/Common/WithAnimation';

import CountItem from './CountItem';

import { txtDay, txtHour, txtMinutes, txtSeconds } from './locales';

function CountContainer({ lang }) {
  const { days, hours, minutes, seconds } = useDateCountdown();

  return (
    <Flex gap="32px" flexDir="column" padding="0 32px" alignItems="center" justifyContent="center">
      <Flex gap="8px" alignItems="center" justifyContent="space-between">
        <WithAnimation left>
          <CountItem text={txtDay[lang]} number={days} />
        </WithAnimation>
        <WithAnimation right>
          <CountItem text={txtHour[lang]} number={hours} />
        </WithAnimation>
      </Flex>
      <Flex gap="8px" alignItems="center" justifyContent="space-between">
        <WithAnimation left>
          <CountItem text={txtMinutes[lang]} number={minutes} />
        </WithAnimation>
        <WithAnimation right>
          <CountItem text={txtSeconds[lang]} number={seconds} />
        </WithAnimation>
      </Flex>
    </Flex>
  );
}

CountContainer.propTypes = {
  lang: string,
};

CountContainer.defaultProps = {
  lang: 'id',
};

export default React.memo(CountContainer);
