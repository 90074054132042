import { css } from '@emotion/react';

export const styleWishlish = css`
  .box-wishes {
    width: 400px;
    padding: 24px 32px
  }
  .button__arrowdown {
    visibility: visible;
    opacity: 1;
  }

  &:hover {
    .button__arrowdown {
      transition: visibility 0s, opacity 0.5s linear;
      opacity: 0;
    }
  }
  .dots-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .dots-container > button {
    height: 8px !important;
    width: 8px !important;
    border-radius: 50% !important;
    margin: 8px 4px !important;
    background: #BCB8C8 !important;
  }

  .dots-container > button:nth-of-type(n + 4) {
    display: none;
  }
  .assets-butterfly {
    position: absolute;
    top: -75px;
    left: 0px;
    z-index: 5;
  }
  .assets-butterfly-img {
    width: 80px;
  }
  .assets-flower {
    position: absolute;
    top: -30px;
    right: -220px;
    z-index: 5;
  }
  .assets-flower-img {
    width: 300px;
    transform: rotateY(180deg);
  }
  
  @media screen and (max-width:420px) {
    .box-wishes {
      width: 300px;
      padding: 16px 24px
    }
    .assets-butterfly {
      position: absolute;
      top: -60px;
      left: 0px;
      z-index: 5; 
    }
    .assets-butterfly-img {
      width: 70px;
    }
    .assets-flower {
      position:absolute;
      top: -26px;
      right: -135px;
      z-index: 5;
    }
    .assets-flower-img {
      width: 200px;
    }
  }
`;
