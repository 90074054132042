import React from 'react';

import { Box, Center, Image, Button, Heading, Text } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import LazyImage from '@/components/Common/LazyImage';
import useLang from '@/hooks/useLang';

import { IG_FILTER_URL } from '@/constants';
import { BUTTON_PADDING_PROPS, BUTTON_PROPS } from '@/constants/colors';
import { ASSETS_LEAF, ASSETS_FLOWER_1, ASSETS_FLOWER_2A, BG_INSTAGRAM_FILTER} from '@/constants/assets';
import { TITLE_SECTION_STYLES, CONTENT_STYLES } from '@/constants/typography';

import txt from './locales';

function InstagramFilter() {
  const lang = useLang();

  return (
    <Box
      marginTop="-16px"
      pos="relative"
    >
       {/* ASSETS LEFT */}
      <Box position="absolute" top="90px" right="-100px" zIndex="2" className='animation__flower'>
        <WithAnimation type="zoom">
          <Image src={ASSETS_LEAF} width="220px"></Image>
        </WithAnimation>
      </Box>
      <Box position="absolute" top="45px" right="-58px" zIndex="5">
        <WithAnimation type="zoom" delay={1000}>
          <Image src={ASSETS_FLOWER_1} width="140px"></Image>
        </WithAnimation>
      </Box>

      {/* IMAGE CENTER */}
      <Box padding="24px" width="350px" mx="auto">
        <Center>
          <WithAnimation>
            <Heading
              {...TITLE_SECTION_STYLES}
              textTransform="lowercase"
              px="8px"
            >
              wedding filter
            </Heading>
          </WithAnimation>
        </Center>
        <WithAnimation>
          <Text
            {...CONTENT_STYLES}
            align="center"
            padding="16px 12px"
            fontStyle="italic"
          >
            {txt.subTitle[lang]}
          </Text>
        </WithAnimation>
      </Box>
      <WithAnimation type="zoom">
        <Box pos="relative" width="300px" mx="auto">
          <LazyImage
            src={BG_INSTAGRAM_FILTER}
            width="100%"
            maxW="300px"
            height="auto"
            margin="0 auto"
            pos="relative"
          />
          {/* ASSETS RIGHT */}
          <Box pos="absolute" top="-50px" right="-55px" transform="rotateY(180deg)">
            <WithAnimation>
              <Image src={ASSETS_FLOWER_2A} width="110px" transform="rotate(36deg)" />
            </WithAnimation>
          </Box>
          {/* ASSETS LEFT */}
          <Box pos="absolute" bottom="-30px" left="-55px">
            <WithAnimation >
              <Image src={ASSETS_FLOWER_2A} width="110px" transform="rotate(36deg)" />
            </WithAnimation>
          </Box>

        </Box>
      </WithAnimation>
      <Box padding="0 32px">
        {/* Button Section */}
        <WithAnimation>
          <Center>
            <a href={IG_FILTER_URL} target="_blank" rel="noreferrer">
              <Button
                size="sm"
                margin="16px 0 32px 0"
                letterSpacing="1px"
                {...BUTTON_PROPS}
                {...BUTTON_PADDING_PROPS}
              >
                {txt.textButton[lang]}
              </Button>
            </a>
          </Center>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default React.memo(InstagramFilter);
