import { css } from '@emotion/css';

export const styleCountdown = css`
.flower-left {
  position: absolute; 
  top: 0px;
  left: -80px;
  width: 280px;
}
.flower-right {
  position: absolute; 
  top: 30px;
  right: -60px;
  width: 280px;
}
@media screen and (max-width:420px) {
  .flower-left {
    position: absolute; 
    top: 80px;
    left: -40px;
    width: 190px;
  }
  .flower-right {
    position: absolute; 
    top: 100px;
    right: 30px;
    width: 200px
  }
}

@media screen and (max-width:320px) {
  .flower-left {
    position: absolute; 
    top: 70px;
    left: -80px;
    width: 190px;
  }
  .flower-right {
    position: absolute; 
    top: 90px;
    right: 70px;
    width: 200px
  }
}
`