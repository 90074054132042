import React, { useState } from 'react';
import dayjs from 'dayjs';

import { arrayOf, bool, object, number } from 'prop-types';
import { Box, Text } from '@chakra-ui/react';
import Carousel from 'react-simply-carousel';

import { styleWishlish } from './styles';
import { HEADING_STYLES, CONTENT_STYLES } from '@/constants/typography';
import { BG_LAYER, BG_LAYER_BORDER } from '@/constants/colors';

const WishItem = (props) => {
  const { item, index } = props;

  return (
    <Box 
      key={`wishlist-${index}`} 
      className="box-wishes"
      bgColor={BG_LAYER} 
      border="1px solid" 
      borderColor={BG_LAYER_BORDER}
      borderRadius="16px" 
      width="100%" 
      textAlign="center" 
      mr="4px"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
      >
      <Box>
        <Text
          {...HEADING_STYLES}
          textTransform="capitalize"
          color="mainColorText"
        >
          {item.name}
        </Text>
        <Text 
          {...CONTENT_STYLES}
          paddingTop="16px">
          {item.wish}
        </Text>
      </Box>
      <Text 
        {...CONTENT_STYLES} 
        color="alternativeColorText"
        fontSize="14px"
        marginTop="16px">
        {dayjs(item.time).format('DD MMM YY | hh:mm A')}
      </Text>
    </Box>
  );
};

WishItem.propTypes = {
  item: object,
  index: number,
};

WishItem.defaultProps = {
  item: {},
  index: 0,
};

function Wishlist({ wishlistData }) {
  const [activeSlide, setActiveSlide] = useState(0);

  const renderWishlist = () => {
    let finalResult = [];
    let wishlist = [];
    let pinnedWishlist = [];

    wishlistData.forEach((item, index) => {
      if (item.isPinByAdmin === 1) {
        pinnedWishlist.push(<WishItem key={index} item={item} index={index} />);
      } else {
        wishlist.push(<WishItem key={index} item={item} index={index} />);
      }
    });

    wishlist = wishlist.reverse();
    finalResult = [...pinnedWishlist, wishlist];

    return finalResult;
  };

  return (
    <Box css={styleWishlish} id="wishlist__box">
      {wishlistData.length > 0 && (
        <Carousel
          autoplay
          autoplayDelay={3000}
          preventScrollOnSwipe
          swipeTreshold={60}
          itemsToShow={1}
          centerMode
          speed={400}
          activeSlideIndex={activeSlide}
          onRequestChange={setActiveSlide}
          responsiveProps={[{ itemsToShow: 1, centerMode: true, minWidth: 200 }]}
          forwardBtnProps={{ style: { display: 'none' } }}
          backwardBtnProps={{ style: { display: 'none' } }}
          dotsNav={{
            show: true,
            containerProps: {
              className: 'dots-container',
              style: {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              },
            },
            itemBtnProps: {
              style: {
                height: 4,
                width: 4,
                borderRadius: '50%',
                border: 0,
                margin: 2,
                background: 'var(--chakra-colors-blackAlpha-500)',
              },
            },
            activeItemBtnProps: {
              style: {
                height: 4,
                width: 4,
                borderRadius: '50%',
                border: 0,
                margin: 2,
                background: 'var(--chakra-colors-blackAlpha-900)',
              },
            },
          }}
        >
          {renderWishlist()}
        </Carousel>
      )}
    </Box>
  );
}

Wishlist.propTypes = {
  loading: bool,
  wishlistData: arrayOf(object),
};

Wishlist.defaultProps = {
  loading: false,
  wishlistData: [],
};

export default Wishlist;
