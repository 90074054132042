import React from 'react';
import { Box, Center, Text, Link, Image, Divider } from '@chakra-ui/react';

import useLang from '@/hooks/useLang';
import useInvitation from '@/hooks/useInvitation';

import LazyImage from '@/components/Common/LazyImage';
import WithAnimation from '@/components/Common/WithAnimation';

import {
  ENABLE_COLLABORATION,
  SELECTED_VENDOR_COLLABORATION,
} from '@/constants/feature-flags';
import { ASSETS_FLOWER_1, ASSETS_BUTTERFLY, LOGO_INVITATO, ASSETS_BUILDING_INVERT } from '@/constants/assets';
import { BRIDE_HASHTAG, INVITATO_URL, SOUND_BY, SOUND_URL, THE_BRIDE } from '@/constants';

import { FOOTER_LINK_PROPS } from './types';
import txt from './locales';
import { HEADING_STYLES, CONTENT_STYLES } from '@/constants/typography';

function FooterSection() {
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box width="100%" pos="relative">
      {/* ASSETS */}
      <Box pos="absolute" top="24px" left="-137px" transform="rotate(348deg)" zIndex="10">
        <WithAnimation type="zoom">
          <Image src={ASSETS_FLOWER_1} width="250px" />
        </WithAnimation>
      </Box>
     
      <Box pos="absolute" top="-26px" right="-103px" zIndex="10">
        <WithAnimation type="zoom">
          <Image src={ASSETS_FLOWER_1} width="200px" transform="rotateY(180deg)" />
        </WithAnimation>
      </Box>

      {/* CLOSING */}
      <Box 
        bgImage="linear-gradient(to bottom, transparent 0%, #FFF5EA 10%, #FFF5EA 40%, transparent 100%)"
        textAlign="center" color="mainColorText" width="100%" position="relative" paddingTop="80px">
        <Box pos="absolute" top="0px" left="50%" transform="translate(-50%)" zIndex="10">
          <WithAnimation delay={300}>
            <Image src={ASSETS_BUTTERFLY} width="80px" className="animation-up-and-down" />
          </WithAnimation>
        </Box>
        <Box padding="40px 48px">
          <WithAnimation>
            <Text {...CONTENT_STYLES} fontSize="20px" fontStyle="italic" margin="24px">
              {isInvitation ? txt.closingInvitation[lang] : txt.closingAnnouncement[lang]}
            </Text>
          </WithAnimation>

          {/* BUILDING COVER SECTION */}
            <WithAnimation>
              <>
                <Text
                  {...CONTENT_STYLES}
                  fontFamily="heading"
                  fontSize="45px"
                  textTransform="lowercase"
                >
                  much love,
                </Text>
                <Text
                  {...HEADING_STYLES}
                  fontSize="36px"
                  fontStyle="italic"
                  marginBottom="2px"
                  lineHeight="1.5em"
                >
                  {THE_BRIDE}
                </Text>
                <Text
                  {...HEADING_STYLES}
                  fontSize="22px"
                  fontStyle="italic"
                  marginBottom="240px"
                >
                  {BRIDE_HASHTAG}
                </Text>
              </>
            </WithAnimation>

        </Box>
        <Box 
          pos="absolute"
          left="0"
          bottom="0"
          width="100%"
        >
          <Image src={ASSETS_BUILDING_INVERT} width="100%" opacity=".6" />
        </Box>
      </Box>

      {/* INVITATO SECTION */}
      <Box  {...CONTENT_STYLES} bgColor="bgOpacity" padding="24px" textAlign="center" color="white" marginTop="-50px">
        {/* INVITATO SECTION */}
        <Box>
          <WithAnimation>
            <Center>
              <Link href={INVITATO_URL} target="_blank">
                <LazyImage src={LOGO_INVITATO} maxWidth="110px" margin="0" />
              </Link>
            </Center>
          </WithAnimation>
          <WithAnimation>
            <Text textAlign="center" fontSize="sm" margin="0 0 12px 0">
              Created with love by Invitato
            </Text>
          </WithAnimation>
          {ENABLE_COLLABORATION && (
            <Box margin="-5px 0 16px">
              <Center>
                <Divider
                  margin="5px 0 12px"
                  maxWidth="60px"
                  opacity="1"
                  height="1px"
                  bgColor="white"
                  border="none"
                  borderBottom="none"
                />
              </Center>
              <Center>
                <Link
                  href={`https://www.instagram.com/${SELECTED_VENDOR_COLLABORATION.instagram}/`}
                  target="_blank"
                >
                  <Image
                    src={SELECTED_VENDOR_COLLABORATION.imgUrl}
                    {...SELECTED_VENDOR_COLLABORATION.imgProps}
                  />
                </Link>
              </Center>
              <Center>
                <Text color="white" align="center" fontSize="xs">
                  In Collaboration with {SELECTED_VENDOR_COLLABORATION.vendorName}
                </Text>
              </Center>
            </Box>
          )}
          <Text {...FOOTER_LINK_PROPS}>
            {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Right Reserved`}
          </Text>
          <Link {...FOOTER_LINK_PROPS} href={SOUND_URL}>
            <Text
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >{`Song by ${SOUND_BY}`}</Text>
          </Link>
          <Link
            {...FOOTER_LINK_PROPS}
            href="https://pngtree.com/freepng/wedding-european-arch-roman-arch-material_7098799.html"
          >
            <Text
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >{`Arch by PNG Tree`}</Text>
          </Link>
          <Link
            {...FOOTER_LINK_PROPS}
            href="https://collection.cooperhewitt.org/objects/18558563/#image-rights"
          >
            <Text
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >{`Wallpaper Design by Cooper Hewitt`}</Text>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(FooterSection);
